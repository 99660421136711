const defaultLanguage = "es"
const languages = ["es", "pt"]

const LOCALE_ES_ES = "es-ES"
const LOCALE_PT_PT = "pt-PT"

const ctfLanguages = {
    es: LOCALE_ES_ES,
    pt: LOCALE_PT_PT,
}

const getLangAttribute = pathname => {
    let locale = defaultLanguage

    if (pathname.startsWith(`/${LOCALE_ES_ES.split("-")[0].toLowerCase()}`)) {
        locale = LOCALE_ES_ES.split("-")[0]
    } else if (pathname.startsWith(`/${LOCALE_PT_PT.split("-")[0].toLowerCase()}`)) {
        locale = LOCALE_PT_PT.split("-")[0]
    }

    return locale
}
const getUrlLocale = locale => {
    switch (locale) {
        case LOCALE_ES_ES:
            return "es"
        case LOCALE_PT_PT:
            return "pt"
        default:
            return null
    }
}
const localeErrorPageMatches = {
    es: `/${getUrlLocale(LOCALE_ES_ES)}/*`,
    pt: `/${getUrlLocale(LOCALE_PT_PT)}/*`,
}

module.exports = {
    defaultLanguage,
    languages,
    LOCALE_ES_ES,
    LOCALE_PT_PT,
    ctfLanguages,
    getLangAttribute,
    localeErrorPageMatches
}
