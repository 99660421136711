/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

import "@fontsource/poppins/400.css"
import "@fontsource/poppins/500.css"
import "@fontsource/poppins/600.css"
import "@fontsource/poppins/700.css"
import "@fontsource/young-serif/400.css"

import "./src/styles/tailwind.css"

import { labels as recipeLabels } from "./src/constants/recipe.constants"
import { getLangAttribute } from "./src/constants/locale.constants"

export const onRouteUpdate = ({ location }) => {
    const { pathname } = location
    document.documentElement.lang = getLangAttribute(pathname)
}
export const shouldUpdateScroll = ({ prevRouterProps, routerProps: { location } }) => {
    const currentPathname = location.pathname
    const prevPathname = prevRouterProps?.location.pathname

    const currentLang = getLangAttribute(currentPathname)
    const prevLang = getLangAttribute(prevPathname || "")

    const currentPathWithoutLang = currentPathname.replace(`/${currentLang}`, "") || "/"
    const prevPathWithoutLang = prevPathname?.replace(`/${prevLang}`, "") || "/"

    const isRecetasPage =
        currentPathWithoutLang === recipeLabels[currentLang].slug &&
        (prevPathWithoutLang === recipeLabels[currentLang].slug || prevPathWithoutLang === `${recipeLabels[currentLang].slug}/`)

    if (isRecetasPage) {
        return false
    }

    return true
}
