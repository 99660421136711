const labels = {
    es: {
        slug: "/recetas", // For RDP and RLP
        rdpCtaLabel: "Ver todos",
        cookingType: "Forma de cocinar",
        occasion: "Ocasiones",
        special: "Recetas especiales",
        HPP_DEFAULT: 8,
        RECIPE_IMAGE_FALLBACK_URL: "https://http.cat/images/404.jpg",
        CARD_PHOTO_TYPE_ID: 2181,
        BANNER_DESKTOP_PHOTO_TYPE_ID: 2184,
        VIDEO_PORTRAIT_TYPE: "Recipe video shorts",
        VIDEO_LANDSCAPE_TYPE: "Recipe video",
        loading: "Cargando recetas...",
        notFound: "No se encontraron recetas",
        loadMoreDefault: "Cargar más",
        error: "Hubo un error al cargar las recetas, por favor intente nuevamente más tarde",
    },
    pt: {
        slug: "/receitas", // For RDP and RLP
        rdpCtaLabel: "Ver todos",
        cookingType: "Formas de cozinhar",
        occasion: "Ocasiões",
        special: "Receitas especiais",
        HPP_DEFAULT: 8,
        RECIPE_IMAGE_FALLBACK_URL: "https://http.cat/images/404.jpg",
        CARD_PHOTO_TYPE_ID: 2191,
        BANNER_DESKTOP_PHOTO_TYPE_ID: 2193,
        VIDEO_PORTRAIT_TYPE: "Recipe video shorts",
        VIDEO_LANDSCAPE_TYPE: "Recipe video",
        loading: "A carregar mais receitas",
        notFound: "No se encontraron recetas",
        loadMoreDefault: "Cargar más",
        error: "Hubo un error al cargar las recetas, por favor intente nuevamente más tarde",
    },
}

module.exports = { labels }
